import React, { useEffect, useState } from "react";
import ReportTable from "../../../common/components/AdminReportTable";
import "../../AdminStyle/report.css";
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import ReusableTable from "../../../common/tables/Table";
import moment from "moment/moment";
import { useSelector, useDispatch } from "react-redux";
import { getAllReportAction } from "../../../redux/actions/reportAction";
import { Button, Spin } from "antd";
import searchIcon from '../../../data/Group 536.png';

export default function Reports() {
  const { userId } = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState('');
  const [selectedReport, setSelectedReport] = useState('Merchant'); // State to track the selected report
  const dispatch = useDispatch();
  const { loading: allReportsLoading, allReports: reports, data: report } = useSelector((state) => state.getAllReport);

  useEffect(() => {
    dispatch(getAllReportAction(userId));
  }, [dispatch]);

  const reporttableData = (reports && Array.isArray(reports))
    ? reports.filter(report =>
      report.companyName.toLowerCase().includes(searchText.toLowerCase())
    ).map(reportData => ({
      companyName: reportData?.companyName,
      lender_owner_name : reportData?.lender_owner_name,
      lender_owner_mail : reportData?.lender_owner_mail,
      paybackAmount: reportData?.paybackAmount,
      date: reportData?.date,
      companyNameFunded: reportData?.lender,
      lender: reportData?.lender,
      fundedAmount: reportData?.fundedAmount,
      upsell: reportData?.upsellPercentage,
      split: reportData?.split,
      teamCM: reportData?.teamCommision,
      leader: reportData?.leader,
    })) : [];

  const sortedreportsData = reporttableData?.sort((a, b) => moment(b.date) - moment(a.date));

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(amount);
  };

  const merchantColumn = React.useMemo(
    () => [
      { Header: 'Company Name', accessor: 'companyName', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
      { Header: 'Payback Amount', accessor: 'paybackAmount', Cell: ({ value }) => <span className="w-[120px] block">{formatAmount(value)}</span> },
      { Header: 'Date', accessor: 'date', Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span> },
      { Header: 'Company Name (funded)', accessor: 'companyNameFunded', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
      { Header: 'Funded Amount', accessor: 'fundedAmount', Cell: ({ value }) => <span className="w-[120px] block">{formatAmount(value)}</span> },
      { Header: 'Upsell %', accessor: 'upsell', Cell: ({ value }) => <span className="w-[60px] block">{value}%</span> },
      { Header: 'Split', accessor: 'split', Cell: ({ value }) => <span className="w-[120px] block">{formatAmount(value)}</span> },
      { Header: 'Team CM', accessor: 'teamCM', Cell: ({ value }) => <span className="w-[120px] block">{formatAmount(value)}</span> },
      { Header: 'Leader', accessor: 'leader', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
    ],
    []
  );

  const lenderColumn = React.useMemo(
    () => [
      { Header: 'Lender name', accessor: 'lender', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
      { Header: 'Owner\'s name', accessor: 'lender_owner_name', Cell: ({ value }) => <span className="w-[120px] block">{(value)}</span> },
      { Header: 'Mail', accessor: 'lender_owner_mail', Cell: ({ value }) => <span>{(value)}</span> },
      { Header: 'Merchant Name', accessor: 'companyName', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
      { Header: 'Payment frequency', accessor: 'fundedAmount', Cell: ({ value }) => <span className="w-[120px] block">{formatAmount(value)}</span> },
      { Header: 'Payment', accessor: 'leader1', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
      { Header: 'Fumding amount', accessor: 'upsell', Cell: ({ value }) => <span className="w-[60px] block">{value}%</span> },
      { Header: 'Number of payments', accessor: 'split', Cell: ({ value }) => <span className="w-[120px] block">{formatAmount(value)}</span> },
      { Header: 'Payback amount', accessor: 'teamCM', Cell: ({ value }) => <span className="w-[120px] block">{formatAmount(value)}</span> },
      { Header: 'Position', accessor: 'leader2', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
      { Header: 'Credit', accessor: 'leader3', Cell: ({ value }) => <span className="w-[120px] block">{value}</span> },
    ],
    []
  );

  return (
    <div className="flex h-screen">
      <DashboardTab />
      <div className="reportContainer flex-1 flex flex-col">
        <GoodMorningBar />
        <main className="flex-1 bg-background-color p-6 h-full flex flex-col overflow-x-auto">
          {allReportsLoading ? (
            <div className="w-full pt-16 flex justify-center items-center">
              <Spin />
            </div>
          ) : (
            <div className="container gap-0 flex justify-between items-center">
                <div className="flex justify-between items-center w-full">
              <div className="container-menu">
              
              <div className="flex gap-4">
                <div className={`${selectedReport === 'Merchant' ? 'sendMail' : 'paid'}`} onClick={() => setSelectedReport('Merchant')}>Merchant Report</div>
                <div className={`${selectedReport === 'Lender' ? 'sendMail' : 'paid'}`} onClick={() => setSelectedReport('Lender')}>Lender Report</div>
              </div>
             
              </div>
   
    <Button 
  className="export-btn px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
  onClick={console.log("")}
>
  Export
</Button>
</div>
              <div className="mt-4 text-black">
                <div className="bg-white rounded-tl-xl rounded-tr-xl p-3">
                  <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                    <img src={searchIcon} alt="Search Icon" className="w-8 h-8 mr-2" />
                    <input
                      type="text"
                      placeholder="Search information"
                      className="bg-background-color focus:outline-none pl-3 w-full"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="table w-full table-fixed">
                  <ReusableTable columns={selectedReport === 'Merchant' ? merchantColumn : lenderColumn} data={sortedreportsData} />
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
