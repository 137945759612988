import React, { useEffect, useMemo } from 'react';
import './DashboardTabs.css';
import { MdOutlineDashboard } from "react-icons/md";
import { LiaNotesMedicalSolid } from "react-icons/lia";
import { GrNotes } from "react-icons/gr";
import { CgNotes } from "react-icons/cg";
import { TiFolderDelete } from "react-icons/ti";
import { TbBasketDollar } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { VscSignOut } from "react-icons/vsc";
import Logo from "../../../data/Group 553.png";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from '../../../redux/actions/authAction';
import { useNavigate } from 'react-router-dom';
import { BsGraphDown } from "react-icons/bs";
import { LiaIdCardSolid } from "react-icons/lia";
import { LuDatabase } from "react-icons/lu";
import { SET_ROLE } from '../../../redux/types/authTypes';

const iconMapping = {
  'Dashboard': MdOutlineDashboard,
  'Business Analytics': BsGraphDown,
  'Leads': LiaNotesMedicalSolid,
  'Deals': GrNotes,
  'Submission Board': LiaNotesMedicalSolid,
  'Lender directory': CgNotes,
  'Compliance': TiFolderDelete,
  'Commision portal': TbBasketDollar,
  'Commision Board': TbBasketDollar,
  'Submission Chart': LiaIdCardSolid,
  'Settings': IoSettingsOutline,
  'Logout': VscSignOut,
  'Reports': LuDatabase
};

const DashboardTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.auth);
  const { selectedTab: currentSelected } = useSelector(state => state.auth);

  console.log(currentSelected, 'currentSelected' , 'Local Storage --- ' , localStorage.getItem('tab'));

  const items = useMemo(() => {
    if (role === 'operations') {
      return [
        "Dashboard",
        "Deals",
        "Submission Board",
        "Lender directory",
        "Compliance",
      ];
    } else if (role === 'admin') {
      return [
        "Business Analytics",
        "Leads",
        "Submission Board",
        "Lender directory",
        "Compliance",
        "Submission Chart",
        "Commision Board",
        "Reports"
      ];
    }
    else{
      return [
        "Dashboard",
        "Leads",
        "Deals",
        "Submission Board",
        "Lender directory",
        "Compliance",
        "Commision portal"
      ];
    }
  }, [role]);

  const bottomItems = ["Settings", "Logout"];

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '').replace('-', ' ');
      if (hash) {
        console.log("If tab --- ")
        const capitalizedHash = hash.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        console.log('capitalized hash --- ' , capitalizedHash);
        // if(localStorage.getItem('tab') == null)
        dispatch(setSelectedTab(localStorage.getItem('tab')));
      } else {
        console.log("Else tab --- ")
        dispatch(setSelectedTab((role === 'admin') ? 'Business Analytics' : 'Dashboard'));
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [dispatch, role]);

  useEffect(() => {
    if (!currentSelected) {
      dispatch(setSelectedTab(localStorage.getItem('tab')));
      console.log('capitalized hash --- ' , items[0])
      navigate(`/${items[0].toLowerCase().replace(' ', '-')}`, { replace: true });
    }
  }, [dispatch, navigate, currentSelected, items]);

  const handleSelect = (item) => {
    console.log("Tab items - " , item)
    localStorage.setItem('tab', item);
    if (item === 'Logout') {
      localStorage.removeItem('sessionData');
      dispatch({ type: SET_ROLE, payload: 'agent' });
      dispatch(setSelectedTab(''));
      localStorage.setItem('tab', '');
      navigate('/', { replace: true });
    } else {
      const formattedItem = item.toLowerCase().replace(' ', '-');
      navigate(`/${formattedItem}`, { replace: true });
      dispatch(setSelectedTab(item));
    }
  };

  return (
    <div className="w-[278px] bg-custom-green text-white">
      <div className="fixed">
        <img
          src={Logo}
          alt="Background"
          className="ml-11 h-full w-36 mt-4"
        />
        <div className="menuuu cursor-pointer" >
          {items.map((item, index) => {
            const IconComponent = iconMapping[item];
            const isSelected = currentSelected.toLowerCase() === item.toLowerCase();
            return (
              <a
                key={index}
                className={`menuuu-item  ${isSelected ? 'selected text-black bg-background-color' : ''}`}
                style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingInline: '30px' }}
                id={item.toLowerCase().replace(' ', '-')}
                onClick={() => handleSelect(item)}>
                <IconComponent size={24} />
                <span style={{ fontSize: 13, marginLeft: '16px' }}>
                  {item}
                </span>
              </a>
            );
          })}
        </div>
        <div className="absolute bottom-0 menuuu cursor-pointer">
          {bottomItems.map((item, index) => {
            const IconComponent = iconMapping[item];
            const isSelected = currentSelected === item;
            return (
              <a
                key={index}
                className={`menuuu-item ${isSelected ? 'selected text-black bg-background-color' : ''}`}
                style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', paddingInline: '30px' }}
                id={item.toLowerCase().replace(' ', '-')}
                onClick={() => handleSelect(item)}>
                <IconComponent size={24} />
                <span style={{ fontSize: 13, marginLeft: '16px' }}>
                  {item}
                </span>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardTab;
