// const APIUrl = 'http://localhost:3000/.netlify/functions/server';
const APIUrl = 'https://www.fidifunding.com/crm';
export default APIUrl;


// https://www.fidifunding.com/crm

// https://bizfundapi.netlify.app/.netlify/functions/server

//  https://bizfundapi.netlify.app
