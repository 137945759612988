import React, { useEffect, useState } from "react";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import { Table } from "lucide-react";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChildNavigation from "../../../common/components/SecondaryNavigation/ChildNavigation/ChildNavigation";
import SecondaryNavigation from "../../../common/components/SecondaryNavigation/SecondaryNavigation";
import DealNavigation from "../../../common/components/SecondaryNavigation/DealNavigation/DealNavigation";
import searchIcon from '../../../data/Group 536.png';
import { getAllDealsAction, getUserDealsAction } from '../../../redux/actions/dealsAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import moment from 'moment/moment';
import { HorizontalFilter } from "../../../common/components/HorizontalFilter";
import { DatePicker, Spin } from "antd";
import { FaCircle } from "react-icons/fa";
import { date } from "yup";

const { RangePicker } = DatePicker;

const DealTable = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const [searchText, setSearchText] = useState('');
  const [dealtableData, setDealTableData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [status, setStatus] = useState('');
  const { userId } = useSelector((state) => state.auth);
  const { role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading: allDealsLoading, deals } = useSelector(state => state.getUserDeals);

  useEffect(() => {
    if (role === 'agents') {
      dispatch(getUserDealsAction(userId));
    } else {
      dispatch(getAllDealsAction(userId, role));
    }
  }, []);

  useEffect(() => {
    let filteredData = [];

    if (searchText !== '') {
      const trimmedSearchText = searchText.trim();
      filteredData = deals?.filter((deal) => {
        return (
          deal?.company_name?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          deal?.tag?.toLowerCase().includes(trimmedSearchText.toLowerCase()) ||
          deal?.agent_name?.toLowerCase().includes(trimmedSearchText.toLowerCase())
        );
      });
    } else {
      filteredData = deals;
    }

    if (status && status !== 'All') {
      filteredData = filteredData?.filter((deal) => deal.status === status);
    }
    if (dateRange?.length === 2) {
      const [startDate, endDate] = dateRange;
      // Convert start and end dates to moment objects in UTC
      const startMoment = (startDate);
      const endMoment = (endDate);
      filteredData = filteredData?.filter((deal) => {
        // Convert deal.createdAt to a moment object in UTC
        const dealDate = moment(deal?.createdAt).utc();
        // Compare dealDate with the range [startMoment, endMoment]
        return dealDate.isBetween(startMoment?.format(), endMoment?.format(), null, '[]');  // '[]' includes the boundary dates
      });
    }




    const dealsData = [];
    if (filteredData?.length > 0) {
      filteredData?.forEach((deal, index) => {
        dealsData.push({
          Key: index,
          id: deal?._id,
          status: deal?.status,
          queue: deal?.queue,
          company: deal?.company_name,
          agent: deal?.agent_name,
          date: deal?.createdAt,
          leadId: deal?.tag,
          email: deal?.email,
          phone: deal?.phone,
          city: deal?.city,
          street: deal?.street,
          lead_source: deal?.lead_source,
          openDeal: deal,
          comment: deal?.comment,
        });
      });
    }

    const sortedDealsData = dealsData?.sort((a, b) => moment(b.date) - moment(a.date));
    setDealTableData(sortedDealsData);
  }, [deals, searchText, status, dateRange]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = (dates[0]);
      const endDate = (dates[1]);
      setDateRange([startDate, endDate]);
    } else {
      setDateRange([]);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : value === "Documents Sent" ? 'bg-blue-100' : "bg-pending-background"} text-white rounded-2xl border w-28`}>
            <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : value === "Documents Sent" ? 'bg-blue-400' : 'bg-pendingText'} rounded-full`}></span>
            <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : value === "Documents Sent" ? 'text-blue-500' : "text-pendingText"} font-semibold`}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Queue',
        accessor: 'queue',
        Cell: ({ value }) => (
          <div className="flex justify-center items-center px-3 py-1 font-semibold bg-operation-green text-white rounded-2xl border w-fit ">
            <span className="text-operaion-text-green capitalize">{value}</span>
          </div>
        ),
      },
      {
        Header: 'Agent',
        accessor: 'agent',
        sortType: 'alphanumeric',
      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>,
      },
      {
        Header: 'Lead ID',
        accessor: 'leadId',
      },
      {
        Header: 'Comments',
        accessor: 'comment',
        Cell: ({ value }) => <span className="w-[150px] block">{value || 'No comment yet'}</span>,
      },
      {
        Header: 'Open deal',
        accessor: 'openDeal',
        Cell: ({ value }) => <FiEye onClick={(() => { navigate('/dealtabs', { state: { deal: value } }) })} alt="Open Deal" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />,
      },
    ],
    []
  );

  const options = [
    {
      label: (
        <span className="text-black flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          All
        </span>
      ),
      value: 'All',
    },
    {
      label: (
        <span className="text-green-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Approved
        </span>
      ),
      value: 'Approved',
    },
    {
      label: (
        <span className="text-orange-300 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Pending
        </span>
      ),
      value: 'Pending',
    },
    {
      label: (
        <span className="text-red-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Declined
        </span>
      ),
      value: 'Declined',
    },

    {
      label: (
        <span className="text-blue-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Documents Sent
        </span>
      ),
      value: 'Documents Sent',
    },
    {
      label: (
        <span className="text-yellow-500 flex justify-start items-center gap-2">
          <FaCircle size="10px" />
          Funded
        </span>
      ),
      value: 'Funded',
    },
  ];

  const handleStatusChange = (value) => {
    setStatus(value);
  }

  return (
    <div className="flex h-screen">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow overflow-y-auto mb-5">
          {allDealsLoading ?
            <div className='w-full pt-16 flex justify-center items-center' >
              <Spin />
            </div>
            :
            <div className='mt-4'>
              <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                <div className="flex flex-row justify-center items-center">
                  <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                    <img
                      src={searchIcon}
                      alt="Search Icon"
                      className="w-8 h-8 mr-2"
                    />
                    <input
                      type="text"
                      placeholder="Search information"
                      className="bg-background-color focus:outline-none pl-3 w-full"
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <div className="flex mt-1 space-x-4">
                    <RangePicker
                      className="text-black font-bold"
                      onChange={(dates) => handleDateChange(dates)}
                    />
                  </div>
                </div>
                <HorizontalFilter options={options} onStatusChange={handleStatusChange} />
              </div>
              <ReusableTable columns={columns} data={dealtableData} />
            </div>
          }
        </div>
      </main>
    </div>
  );
};

export default DealTable;
