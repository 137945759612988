// store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import { addLeadReducer, getActivittyLogsReducer, getAllFilesReducer, getAllLeadsReducer, getDeleteLeadReducer, postCsvFileReducer, sendLeadToOperationsReducer, updateLeadReducer, uploadFileReducer } from './reducers/leadsReducers';
import { createNewTaskReducer, fetchChartDataReducer, fetchCompaniesReducer, getAdminCardSubmissionDataReducer, getAdminSubmissionDataReducer, getAllTasksReducer, getBusinessAnalyticsDataReducer, setFundingTargetReducer } from './reducers/dashboardReducer';
import { getUserDetailsReducer, updateUserDetailsReducer } from './reducers/userReducer';
import { approveDealReducer, declineDealReducer, getOldDealsReducer, getOldLenderByDealIdReducer, getUserDealsReducer, sendDealToSubmissionBoardReducer } from './reducers/dealsReducer';
import { addNewLenderReducer, addNewLenderToDealReducer, getAllLendersReducer, getDealsByLenderReducer, sendDealToLenderReducer } from './reducers/lenderReducer';
import { approveLenderInSubmissionBoardReducer, declineLenderInSubmissionBoardReducer, getAllSubmissionsReducer, getApprovedSubmissionsBoardDealReducer, getSubmissionsBoardDealReducer, setShownOldDealsButtonReducer } from './reducers/submissionBoardReducer';
import { deleteLeadReducer, getQueuedLeadsReducer, getRemovedLeadsReducer, postComplianceFormReducer, restoreLeadReducer } from './reducers/complianceReducer';
import { getAllCommisionReducer, getCommisionReducer, paidReducer, requestCommisionReducer } from './reducers/commisionReducer';
import { getSubmissionDataReducer } from './reducers/submissionChartReducer';
import { getAllApprovedLeadersReducer, getAllLeadersReducer } from './reducers/leadersReducers';
import { getAllReportReducer } from './reducers/reportReducer';

const persistedSessionData = localStorage.getItem('sessionData')
  ? JSON.parse(localStorage.getItem('sessionData'))
  : null;

// Preload the state with session data if available
const preloadedState = persistedSessionData
  ? {
      auth: {
        selectedTab: 'Dashboard',
        childSelectedTab: 'Account Settings',
        userId: persistedSessionData.userId,
        name: persistedSessionData.name,
        role: persistedSessionData.role,
      },
    }
  : {};

const store = configureStore({
    reducer: {
        auth: authReducer,
        getAllLeads: getAllLeadsReducer,
        addLead: addLeadReducer,
        updateLead: updateLeadReducer,
        createNewTask: createNewTaskReducer,
        getAllTasks: getAllTasksReducer,
        fetchCompaniesData: fetchCompaniesReducer,
        getUserDetails: getUserDetailsReducer,
        updateUserDetails: updateUserDetailsReducer,
        getActivittyLogs: getActivittyLogsReducer,
        getUserDeals: getUserDealsReducer,
        sendLeadToOperations: sendLeadToOperationsReducer,
        addNewLender: addNewLenderReducer,
        getAllLenders: getAllLendersReducer,
        getAllSubmissions: getAllSubmissionsReducer,
        getOldDeals: getOldDealsReducer,
        getOldLenderByDealId: getOldLenderByDealIdReducer,
        postComplianceForm: postComplianceFormReducer,
        getQueuedLeads: getQueuedLeadsReducer,
        getRemovedLeads: getRemovedLeadsReducer,
        restoreLead: restoreLeadReducer,
        deleteLead: deleteLeadReducer,
        postCsvFile: postCsvFileReducer,
        addNewLenderToDeal: addNewLenderToDealReducer,
        setShownOldDealsButton: setShownOldDealsButtonReducer,
        getDealsByLender: getDealsByLenderReducer,
        uploadFile: uploadFileReducer,
        getAllFiles: getAllFilesReducer,
        getDeleteLeads: getDeleteLeadReducer,
        sendDealToSubmissionBoard: sendDealToSubmissionBoardReducer,
        approveDeal: approveDealReducer,
        declineDeal: declineDealReducer,
        approveLenderInSubmissionBoard: approveLenderInSubmissionBoardReducer,
        declineLenderInSubmissionBoard: declineLenderInSubmissionBoardReducer,
        getSubmissionsBoardDeal: getSubmissionsBoardDealReducer,
        getApprovedSubmissionsBoardDeal: getApprovedSubmissionsBoardDealReducer,
        getCommissions: getCommisionReducer,
        postRequestCommision: requestCommisionReducer,
        getDeleteLender: getDealsByLenderReducer,
        fetchChartData: fetchChartDataReducer,
        setfundingTarget: setFundingTargetReducer,
        getSubmissionData: getSubmissionDataReducer,
        getBusinessAnalyticsData: getBusinessAnalyticsDataReducer,
        getAdminSubmissionData: getAdminSubmissionDataReducer,
        addLeader:addLeadReducer,
        getLeader:getAllLeadersReducer,
        getApprovedLeader:getAllApprovedLeadersReducer,
        getAllCommission: getAllCommisionReducer,
        getAllReport:getAllReportReducer,
        getPaid : paidReducer,
        sendDealToLender: sendDealToLenderReducer
    },
    preloadedState,
});

export default store;
