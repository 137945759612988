import {
  ADD_NEW_LENDER_FAIL,
  ADD_NEW_LENDER_REQUEST,
  ADD_NEW_LENDER_SUCCESS,
  ADD_NEW_LENDER_TO_DEAL_FAIL,
  ADD_NEW_LENDER_TO_DEAL_REQUEST,
  ADD_NEW_LENDER_TO_DEAL_SUCCESS,
  GET_ALL_LENDERS_FAIL,
  GET_ALL_LENDERS_REQUEST,
  GET_ALL_LENDERS_SUCCESS,
  GET_DEALS_BY_LENDER_FAIL,
  GET_DEALS_BY_LENDER_REQUEST,
  GET_DEALS_BY_LENDER_SUCCESS,
  GET_DELETE_LENDERS_FAIL,
  GET_DELETE_LENDERS_REQUEST,
  GET_DELETE_LENDERS_SUCCESS,
  SEND_DEAL_TO_LENDER_FAIL,
  SEND_DEAL_TO_LENDER_REQUEST,
  SEND_DEAL_TO_LENDER_SUCCESS,
} from '../types/lenderTypes';

export const addNewLenderReducer = (state = {
  loading: false,
  addNewLender: {}
}, action) => {
  switch (action.type) {
    case ADD_NEW_LENDER_REQUEST:
      return { loading: true };
    case ADD_NEW_LENDER_SUCCESS:
      return { loading: false, addNewLender: action.payload };
    case ADD_NEW_LENDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getAllLendersReducer = (state = {
  loading: false,
  lenders: []
}, action) => {
  switch (action.type) {
    case GET_ALL_LENDERS_REQUEST:
      return { loading: true };
    case GET_ALL_LENDERS_SUCCESS:
      return { loading: false, lenders: action.payload };
    case GET_ALL_LENDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const addNewLenderToDealReducer = (state = {
  loading: false,
  addNewLender: {}
}, action) => {
  switch (action.type) {
    case ADD_NEW_LENDER_TO_DEAL_REQUEST:
      return { loading: true };
    case ADD_NEW_LENDER_TO_DEAL_SUCCESS:
      return { loading: false, addNewLender: action.payload };
    case ADD_NEW_LENDER_TO_DEAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getDealsByLenderReducer = (state = {
  loading: false,
  dealsLender: []
}, action) => {
  switch (action.type) {
    case GET_DEALS_BY_LENDER_REQUEST:
      return { loading: true };
    case GET_DEALS_BY_LENDER_SUCCESS:
      return { loading: false, dealsLender: action.payload };
    case GET_DEALS_BY_LENDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

// delete leader
export const getDeleteLenderReducer = (state = {
  loading: false,
}, action) => {
  switch (action.type) {
    case GET_DELETE_LENDERS_REQUEST:
      return { loading: true, error: null };
    case GET_DELETE_LENDERS_SUCCESS:
      return { loading: false };
    case GET_DELETE_LENDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const sendDealToLenderReducer = (state = {
  loading: false,
  sendDealToLender: {}
}, action) => {
  switch (action.type) {
    case SEND_DEAL_TO_LENDER_REQUEST:
      return { loading: true };
    case SEND_DEAL_TO_LENDER_SUCCESS:
      return { loading: false, sendDealToLender: action.payload };
    case SEND_DEAL_TO_LENDER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}