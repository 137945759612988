import {
  APPROVE_DEAL_FAIL,
  APPROVE_DEAL_REQUEST,
  APPROVE_DEAL_SUCCESS,
  DECLINE_DEAL_FAIL,
  DECLINE_DEAL_REQUEST,
  DECLINE_DEAL_SUCCESS,
  GET_OLD_DEALS_FAIL,
  GET_OLD_DEALS_REQUEST,
  GET_OLD_DEALS_SUCCESS,
  GET_OLD_LENDER_BY_DEAL_ID_FAIL,
  GET_OLD_LENDER_BY_DEAL_ID_REQUEST,
  GET_OLD_LENDER_BY_DEAL_ID_SUCCESS,
  GET_USER_DEALS_FAIL,
  GET_USER_DEALS_REQUEST,
  GET_USER_DEALS_SUCCESS,
  SEND_DEAL_TO_SUBMISSION_BOARD_FAIL,
  SEND_DEAL_TO_SUBMISSION_BOARD_REQUEST,
  SEND_DEAL_TO_SUBMISSION_BOARD_SUCCESS
} from '../types/dealsTypes';

export const getUserDealsReducer = (state = {
  loading: false,
  deals: []
}, action) => {
  switch (action.type) {
    case GET_USER_DEALS_REQUEST:
      return { loading: true, deals: [] };
    case GET_USER_DEALS_SUCCESS:
      return { loading: false, deals: action.payload };
    case GET_USER_DEALS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getOldDealsReducer = (state = {
  loading: false,
  oldDeals: {}
}, action) => {
  switch (action.type) {
    case GET_OLD_DEALS_REQUEST:
      return { loading: true };
    case GET_OLD_DEALS_SUCCESS:
      return { loading: false, oldDeals: action.payload };
    case GET_OLD_DEALS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getOldLenderByDealIdReducer = (state = {
  loading: false,
  oldLender: []
}, action) => {
  switch (action.type) {
    case GET_OLD_LENDER_BY_DEAL_ID_REQUEST:
      return { loading: true };
    case GET_OLD_LENDER_BY_DEAL_ID_SUCCESS:
      return { loading: false, oldLender: action.payload };
    case GET_OLD_LENDER_BY_DEAL_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const sendDealToSubmissionBoardReducer = (state = {
  loading: false,
  sendDealToSB: {}
}, action) => {
  switch (action.type) {
    case SEND_DEAL_TO_SUBMISSION_BOARD_REQUEST:
      return { loading: true };
    case SEND_DEAL_TO_SUBMISSION_BOARD_SUCCESS:
      return { loading: false, sendDealToSB: action.payload };
    case SEND_DEAL_TO_SUBMISSION_BOARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const approveDealReducer = (state = {
  loading: false,
  approveDeal: {}
}, action) => {
  switch (action.type) {
    case APPROVE_DEAL_REQUEST:
      return { loading: true };
    case APPROVE_DEAL_SUCCESS:
      return { loading: false, approveDeal: action.payload };
    case APPROVE_DEAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}

export const declineDealReducer = (state = {
  loading: false,
  declineDeal: {}
}, action) => {
  switch (action.type) {
    case DECLINE_DEAL_REQUEST:
      return { loading: true };
    case DECLINE_DEAL_SUCCESS:
      return { loading: false, declineDeal: action.payload };
    case DECLINE_DEAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}