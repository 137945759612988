import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from 'react-router';
import DocumentList from "./DocumentList";
import { useRecoilState } from 'recoil';
import ReusableTable from '../../../../../common/tables/Table';
import { useDispatch, useSelector } from 'react-redux';
import { userIdAtom } from '../../../../../atom/authuserData/userdata';
import { Button, Modal, Spin } from 'antd';
import moment from 'moment';
import { TbPointFilled } from 'react-icons/tb';
import { FiEdit2, FiUploadCloud } from "react-icons/fi";
import AddNewLenderToDeal from '../../../../AdminScreens/AddNewLender/AddNewLenderToDeal';
import { deleteLenderFromDealAction, getDealsByLenderAction, sendDealToLenderAction } from '../../../../../redux/actions/lenderAction';
import UploadFileModal from './UploadFileModal';
import { getAllFilesAction } from '../../../../../redux/actions/leadsActions';
import { sendDealToSubmissionBoardAction } from '../../../../../redux/actions/dealsAction';
import { toast } from 'react-toastify';
import { Color } from "antd/es/color-picker";
import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { deleteDealLender } from "../../../../../atom/dashboardData/dealsApi";

export default function AgentDealLender() {
    const [dealsLenderTableData, setDealsLenderTableData] = useState([]);
    const [showAddNewLenderToDealModal, setShowAddNewLenderToDealModal] = useState(false);
    const [showFileuploadModal, setShowFileuploadModal] = useState(false);
    const [allFilesData, setAllFilesData] = useState([])
    const [template, setTemplat] = useState("Greetings, \nPlease see attached deal.")
    const [isEditing, setIsEditing] = useState(false)
    const [subjectTemplate, setSubjectTemplate] = useState("Deal Attachments from BizFund");
    const [individualButtonLoading , setIndividualbuttonloading] = useState(false)

    const handleSubjectChange = (e) => {
        setSubjectTemplate(e.target.value);
    };

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAllFiles = async () => {
        const result = await dispatch(getAllFilesAction(userId, deal?._id));
        if (result?.status === 1) {
            setAllFilesData(result?.files)
        } else {
            setAllFilesData([])
        }
    }

    const { role } = useSelector((state) => state.auth);
    const { userId } = useSelector((state) => state.auth);
    const { selectedTab: tabSelected } = useSelector((state) => state.auth);
    const { loading: allDealsLenderLoading, dealsLender } = useSelector(state => state.getDealsByLender);
    const { loading: sendDealToSBLoading } = useSelector(state => state.sendDealToSubmissionBoard);
    const { deal } = location.state || {};


    const confirmDelete = (lenderId) => {
        console.log("Value --- ", lenderId.original.lender.lender_id)
        Modal.confirm({
            title: 'Are you sure you want to remove this lender?',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => handleDeleteLenderFromDeal(lenderId.original.lender.lender_id),
        });
    };

    useEffect(() => {
        dispatch(getDealsByLenderAction(userId, deal?._id));
        getAllFiles()
    }, []);

    useEffect(() => {
        console.log("Deal Lenders ----- " , dealsLender)
        let filteredData = [];
        if (dealsLender?.length > 0) {
            dealsLender?.forEach((lender) => {
                filteredData.push({
                    lender: {
                        lender_id: lender?.lender_id,
                        company: lender?.lender_name,
                        ceo: `${lender?.owner_firstName}`,
                        ceoFirstName: lender?.owner_firstName,
                        ceoMail: lender?.owner_mail,
                    },
                    position: lender?.posOfWiilng,
                    credit: lender?.credit ?? "N/A",
                    amount: lender?.payment,
                    monthlyMin: lender?.monthly_minimums,
                    status: lender?.status,
                    sendMail: lender?.lender_id,
                    delete: lender?._id,
                    createdAt: lender?.createdAt,
                    mailSent: lender?.mailSent
                });
            });
        }
        setDealsLenderTableData(filteredData);

    }, [dealsLender]);

    const handleDeleteLenderFromDeal = async (lenderId) => {
        console.log(lenderId, "  <--- lenderId", userId, "  <--- User Id", deal?._id, "  <--- Deal Id")
        const result = await deleteDealLender(userId, lenderId, deal?._id)
        // const result = await dispatch(deleteLenderFromDealAction(userId, lenderId , deal?._id));
        if (result?.status === 1) {
            toast.success(result?.data?.message)
            dispatch(getDealsByLenderAction(userId, deal?._id));
        } else {
            toast.error(result?.data?.message)
        }
    }

    const getStatusClassName = (status) => {
        switch (status) {
            case "Pending":
                return "status-pending";
            case "Declined":
                return "status-declined";
            case "Approved":
                return "status-approved";
            default:
                return "";
        }
    };

    const dealsLenderColumns = useMemo(() => {
        const columns = [
            {
                Header: 'Lender',
                accessor: 'lender',
                Cell: ({ value }) => (
                    <div>
                        <div className="text-good-morning-text font-semibold capitalize">{value.company} / {value.ceoFirstName}</div>
                        {/* <div>{value.ceoMail}</div> */}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => (
                    <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
                        <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
                        <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{value}</span>
                    </div>
                ),
            },
            {
                Header: 'Position',
                accessor: 'position',
                Cell: ({ value }) => (
                    <span>{value}</span>
                ),
            },
            {
                Header: 'Credit',
                accessor: 'credit',
                Cell: ({ value }) => (
                    <span>{value}</span>
                ),
            },
            {
                Header: 'Monthly Min',
                accessor: 'monthlyMin',
                Cell: ({ value }) => (
                    <span>{value ? `$${value}` : "-"}</span>
                ),
            },
            {
                Header: 'Send Deal to Lender',
                accessor: 'sendMail',
                Cell: ({ value, row }) => (
                    <div className="flex">
                        <Button
                            loading={individualButtonLoading[value]}
                            onClick={() => sendDealToLender(value, row)}
                            className={`px-4 py-1 rounded focus:outline-none focus:ring-2 focus:ring-opacity-75 ${
                                row.original.mailSent === "true"
                                    ? "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-400 hover:text-white"
                                    : "bg-green-500 text-white hover:bg-green-600 focus:ring-green-400"
                            }`}
                            disabled={row.original.mailSent}
                        >
                            {row.original.mailSent === "true" ? "Sent" : "Send"}
                        </Button>
                    </div>
                ),
            },
            
            { Header: 'Delete',
                accessor: 'delete',
                Cell: ({ row }) => {
                    const lenderId = row.original.lender_id;
                    const dealId = row.original.deal_id;
                    return (
                        <div className="flex">
                            <DeleteOutlined
                                className="text-gray-600 cursor-pointer"
                                size={20}
                                onClick={() => confirmDelete(row)}
                            />
                        </div>
                    );
                },}
        ];
        return columns;
    }, [role , individualButtonLoading]);

    const handleAddNewLender = () => {
        setShowAddNewLenderToDealModal(true);
    }

    const handleTemplateChange = (e) => {
        setTemplat(e.target.value)
    }

    const handleEditClick = () => {
        setIsEditing(!isEditing)
    }

    const sendDealToAllLender = async () => {
        const result = await dispatch(sendDealToSubmissionBoardAction(userId, deal?._id, template, subjectTemplate));
        if (result?.status === 1) {
            toast.success(result?.data?.message)
            dispatch(getDealsByLenderAction(userId, deal?._id));
            // navigate('/submission-board')

        } else {
            toast.success(result?.data?.message)
        }
    }

    const sendDealToLender = async (lenderId , row) => {
        console.log("Row --- " , row)
        setIndividualbuttonloading((prev) => ({ ...prev, [lenderId]: true }));
        try {
        const result = await dispatch(sendDealToLenderAction({
            leadId: deal?._id,
            user_id: userId,
            template: template,
            mailSubject: subjectTemplate,
            lender_id: lenderId
        }));
        console.log("response ind --- " , result)
        if (result?.status === 1) {
            dispatch(getDealsByLenderAction(userId, deal?._id));
            toast.success(result?.message)
            // alert("Success, " , result?.message)
           
        } else {
            toast.error(result?.message)
            alert(result?.message)
        } }catch (error) {
            console.error("Error sending deal to lender:", error);
            toast.error("An unexpected error occurred.");
        } finally {
            // Reset loading state for the specific button
            setIndividualbuttonloading((prev) => ({ ...prev, [lenderId]: false }));
        }
    }

    return (
        <>
            <div className="flex flex-col gap-4 w-full h-[100vh] overflow-y-auto">
                <div className='flex gap-4 min-h-[485px]'>
                    <div className="flex flex-col items-center w-[40%] rounded-lg px-[0.8rem] py-[1rem] bg-white">
                        <div className="flex text-[1.2rem] text-[#0A2759] w-[100%] justify-between font-[500] px-[0.5rem] py-[0.5rem]">
                            Document
                            <Button
                                className="flex gap-2 border border-[#00A762] rounded-lg px-2 py-1.5 items-center text-[1rem]"
                                onClick={() => setShowFileuploadModal(true)}
                            >
                                <FiUploadCloud /> Upload
                            </Button>
                        </div>
                        <DocumentList allFilesData={allFilesData} setAllFilesData={setAllFilesData} dealId={deal?._id} />
                    </div>
                    <div className='w-[60%]'>
                        <div className="bg-white flex justify-between w-full py-5 rounded-tl-md rounded-tr-md">
                            <div className="flex items-center">
                                <div className="ml-8 text-good-morning-text font-bold text-xl">
                                    Lenders
                                </div>
                            </div>
                            {role === 'agents' && tabSelected === 'Submission Board' ?
                                null
                                : <div
                                    className="h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer"
                                    onClick={() => handleAddNewLender()}
                                >
                                    Add new lender
                                </div>}
                        </div>
                        <div className="h-5/6 w-full overflow-x-auto">
                            <Spin spinning={allDealsLenderLoading}>
                                <ReusableTable columns={dealsLenderColumns} data={dealsLenderTableData} maxRow={5} />
                            </Spin>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2 text-[#0A2759] font-[500] w-[100%]">
                    <div className="flex flex-col gap-4 bg-white px-[2rem] py-[1rem] rounded-lg">
                        {/* Template Header */}
                        <div className="flex justify-between gap-2 items-center mt-2 text-[1.2rem]">
                            Template
                            <button
                                className="text-white bg-[#00A762] px-4 py-1 rounded text-[1rem]"
                                onClick={handleEditClick}
                            >
                                {isEditing ? "Save" : "Edit"}
                            </button>
                        </div>

                        {/* Subject Template */}
                        <div className="flex flex-col gap-2">
                            <span className="text-[1rem] font-semibold">Subject</span>
                            <textarea
                                className="flex gap-2 px-3 py-1 h-[50px] rounded-lg border border-[#D0D3D9] outline-none"
                                value={subjectTemplate}
                                onChange={handleSubjectChange}
                                disabled={!isEditing}
                            />
                        </div>

                        {/* Body Template */}
                        <div className="flex flex-col gap-2">
                            <span className="text-[1rem] font-semibold">Body</span>
                            <textarea
                                className="flex gap-2 px-3 py-1 h-[100px] rounded-lg border border-[#D0D3D9] outline-none"
                                value={template}
                                onChange={handleTemplateChange}
                                disabled={!isEditing}
                            />
                        </div>
                    </div>

                    <div className="flex gap-8 self-end mt-2">
                        {/* <Button className="text-[#C80000] font-[500]">Cancel</Button> */}
                        <Button
                            loading={sendDealToSBLoading}
                            className="text-white bg-[#00A762] px-[1rem] py-[5px] rounded-lg"
                            onClick={() => sendDealToAllLender()}
                        >
                            Send deal to all lender
                        </Button>
                    </div>
                </div>
                {showAddNewLenderToDealModal &&
                    <AddNewLenderToDeal
                        deal={deal}
                        showAddNewLenderToDealModal={showAddNewLenderToDealModal}
                        setShowAddNewLenderToDealModal={setShowAddNewLenderToDealModal}
                    />
                }
                {showFileuploadModal &&
                    <UploadFileModal
                        getAllFiles={() => getAllFiles()}
                        deal={deal}
                        visible={showFileuploadModal}
                        onCancel={() => setShowFileuploadModal(false)}
                    />
                }
            </div>
        </>
    )
}