// src/components/LenderScreen.js
import React, { useEffect, useState } from 'react';
import ReusableTable from '../../../../common/tables/Table';
import '../../../../common/components/LenderTable.css'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AddLenderModal from '../../../AdminScreens/AddNewLender/AddLender';
import { getAllLendersAction, getDealsByLenderAction } from '../../../../redux/actions/lenderAction';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../../atom/authuserData/userdata';
import { FiEdit2 } from "react-icons/fi";
import { Spin } from 'antd';
import AddNewLenderToDeal from '../../../AdminScreens/AddNewLender/AddNewLenderToDeal';
import { AddCommentModal } from '../../../../common/components/AddCommentModal';

const LenderPage = () => {
  const { role } = useSelector((state) => state.auth);
  const { userId } = useSelector((state) => state.auth);
  const { selectedTab: tabSelected } = useSelector((state) => state.auth);
  const [showEditLenderModal, setShowEditLenderModal] = useState(false);
  const [showAddNewLenderToDealModal, setShowAddNewLenderToDealModal] = useState(false);
  const [dealsLenderTableData, setDealsLenderTableData] = useState([]);
  const [slectedLender, setSelectedLender] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const { loading: allDealsLenderLoading, dealsLender } = useSelector(state => state.getDealsByLender);
  const { deal } = location.state || {};


  useEffect(() => {
    dispatch(getAllLendersAction(userId));
    dispatch(getDealsByLenderAction(userId, deal?._id));
  }, []);

  useEffect(()=>{
    console.log("Table data :",dealsLender)
  },[dealsLender])

  useEffect(() => {
    console.log("Deals Lender Data:", dealsLender);
    let filteredData = [];
    if (dealsLender?.length > 0) {
      dealsLender?.forEach((lender) => {
        filteredData.push({
          lender: {
            company: lender?.lender_name,
            ceo: `${lender?.owner_firstName}`,
            ceoFirstName: lender?.owner_firstName,
            ceoMail: lender?.owner_mail,
            comment:lender?.comment || "No comment yet"
          },
          frequency: lender?.frequency,
          funding_amount: lender?.funding_amount,
          num_of_payment: lender?.num_of_payment,
          payback_amount: lender?.payback_amount,
          position: lender?.lenderIndealPosition,
          credit: lender?.credit,
          payment: lender?.payment === "-" ? null : lender?.payment,
          monthlyMin: lender?.monthly_minimums,
          createdAt: lender?.createdAt,
          status: lender?.status,
          edit: lender,
        });
      });
    }
    setDealsLenderTableData(filteredData);

  }, [dealsLender]);

  const handleLenderClick = (lender) => {
    console.log("Lender --- " , lender)
    setSelectedLender(lender);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedLender(null);
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case "Declined":
        return "status-declined";
      case "Approved":
        return "status-approved";
      default:
        return "";
    }
  };

  const handleShowEditLenderModal = (lender) => {
    setSelectedLender(lender);
    setShowEditLenderModal(true);
  }

  const dealsLenderColumns = React.useMemo(() => {
    const columns = [
      {
        Header: 'Lender',
        accessor: 'lender',
        Cell: ({ value  , row}) => (
          <div className="relative group">
            <div
              className="text-good-morning-text font-semibold capitalize cursor-pointer"
              onClick={() => handleLenderClick(row.original)}
            >
              {value.company} / {value.ceoFirstName}
            </div>
            <div>{value.ceoMail}</div>
            {value.comment && (
              <div className="absolute left-0 top-full mt-1 hidden group-hover:block bg-gray-100 text-gray-800 text-sm px-2 py-1 rounded shadow-md w-max">
                {value.comment}
              </div>
            )}
          </div>
        ),
      },
      
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <div className={`flex items-center space-x-2 px-2 py-1  ${value === 'Declined' ? 'bg-declined-bgcolor' : value === "Approved" ? "bg-operation-green" : "bg-pending-background"} text-white rounded-2xl border w-28`}>
            <span className={`h-2 w-2 ${value === 'Declined' ? 'bg-red-600' : value === "Approved" ? 'bg-green-500' : 'bg-pendingText'} rounded-full`}></span>
            <span className={` ${value === 'Declined' ? 'text-red-600' : value === "Approved" ? "text-approved-text" : "text-pendingText"} font-semibold`}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        Cell: ({ value }) => (
          <span>{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Funding Amount',
        accessor: 'funding_amount',
        Cell: ({ value }) => (
          <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
      {
        Header: 'No. of Payment',
        accessor: 'num_of_payment',
        Cell: ({ value }) => (
          <span>{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Payback Amount',
        accessor: 'payback_amount',
        Cell: ({ value }) => (
          <span className="w-[150px] block">{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
      {
        Header: 'Position',
        accessor: 'position',
        Cell: ({ value }) => (
          <span>{value ? value : "-"}</span>
        ),
      },
      {
        Header: 'Credit',
        accessor: 'credit',
        Cell: ({ value }) => (
          <span>{value ? `${value}` : "-"}</span>
        ),
      },
      {
        Header: 'Payment',
        accessor: 'payment',
        Cell: ({ value }) => (
          <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
      {
        Header: 'Monthly Min',
        accessor: 'monthlyMin',
        Cell: ({ value }) => (
          <span>{value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value) : "-"}
          </span>
        ),
      },
    ];

    if (role !== "agents" && tabSelected === 'Submission Board') {
      columns.push({
        Header: 'Add lender to deal',
        accessor: 'edit',
        Cell: ({ value }) => (
          <div className="flex">
            <FiEdit2 className="text-gray-600 cursor-pointer" size={20} onClick={() => handleShowEditLenderModal(value)} />
          </div>
        ),
      });
    }

    return columns;
  }, [role]);

  const handleAddNewLender = () => {
    setShowAddNewLenderToDealModal(true);
  }

  // we are showing this component only when the role is operations and tabSelected is Submission Board
  return (
    <div className="my-5 rounded-lg  ml-1 h-full overflow-y-scroll">
      <div className="bg-white flex justify-between w-full mr-14 mt-6 py-5 rounded-tl-md rounded-tr-md">
        <div className="flex items-center">
          <div className="ml-8 text-good-morning-text font-bold text-xl">
            Lenders
          </div>
        </div>
        {role === 'agents' && tabSelected === 'Submission Board' ?
          null
          : <div
            className="h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer"
            onClick={() => handleAddNewLender()}
          >
            Add new lender
          </div>}
      </div>
      <div className="h-auto">
        <Spin spinning={allDealsLenderLoading}>
          <ReusableTable columns={dealsLenderColumns} data={dealsLenderTableData} />
          <AddCommentModal
            isOpen={isModalOpen}
            lender={slectedLender}
            onClose={handleCloseModal}
          />
        </Spin>
      </div>
      <div className="h-2"></div>
      {showAddNewLenderToDealModal &&
        <AddNewLenderToDeal
          deal={deal}
          showAddNewLenderToDealModal={showAddNewLenderToDealModal}
          setShowAddNewLenderToDealModal={setShowAddNewLenderToDealModal}
        />
      }
      {showEditLenderModal &&
        <AddLenderModal
          deal={deal}
          slectedLender={slectedLender}
          showEditLenderModal={showEditLenderModal}
          setShowEditLenderModal={setShowEditLenderModal}
        />
      }
    </div>
  );
};

export default LenderPage;
