// Dashboard.js
import React, { useState } from "react";
import '../../../common/components/Calender.css';
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import AddNewLenderForm from './AddNewLenderForm';
import BackButton from "../../tabScreens/SubmissionBoard/backButton";

const AddNewLender = () => {
  const role = useSelector((state) => state.role);

  return (
    <div className="flex h-screen">
      <div className="flex space-x-24 w-[278px]">
        <DashboardTab />
      </div>
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col ">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow mb-5 mt-8 overflow-y-auto">
        <BackButton/>
          <div className="bg-white rounded-lg py-5 mt-5 px-7 h-[auto]">
            <AddNewLenderForm />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddNewLender;