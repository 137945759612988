import { axiosGet, axiosPost } from '../axios';
import {
  ADD_LEAD_FAIL,
  ADD_LEAD_REQUEST,
  ADD_LEAD_SUCCESS,
  GET_ACTIVITY_LOGS_FAIL,
  GET_ACTIVITY_LOGS_REQUEST,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_ALL_FILES_FAIL,
  GET_ALL_FILES_REQUEST,
  GET_ALL_FILES_SUCCESS,
  GET_ALL_LEADS_FAIL,
  GET_ALL_LEADS_REQUEST,
  GET_ALL_LEADS_SUCCESS,
  POST_CSV_FILE_FAIL,
  POST_CSV_FILE_REQUEST,
  POST_CSV_FILE_SUCCESS,
  POST_FILE_FAIL,
  POST_FILE_REQUEST,
  POST_FILE_SUCCESS,
  RESET_ALL_FILES,
  SEND_LEAD_TO_OPERATIONS_FAIL,
  SEND_LEAD_TO_OPERATIONS_REQUEST,
  SEND_LEAD_TO_OPERATIONS_SUCCESS,
  UPDATE_LEAD_FAIL,
  UPDATE_LEAD_REQUEST,
  UPDATE_LEAD_SUCCESS,
  GET_DELETE_LEADS_FAIL,
  GET_DELETE_LEADS_REQUEST,
  GET_DELETE_LEADS_SUCCESS,
} from '../types/leadsTypes';

export const getAllLeadsAction = (userId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_LEADS_REQUEST });

    const { data } = await axiosGet(`leads/getAllLeads?user_id=${userId}`);
    dispatch({ type: GET_ALL_LEADS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_ALL_LEADS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const addLeadAction = (userId, leadData) => async (dispatch) => {
  try {
    dispatch({ type: ADD_LEAD_REQUEST });

    const { data } = await axiosPost(`leads/createLead?user_id=${userId}`, leadData);
    dispatch({ type: ADD_LEAD_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    console.log(error, 'error');
    dispatch({
      type: ADD_LEAD_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
    return error.response.data;
  }
}

export const updateLeadAction = (userId, leadData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LEAD_REQUEST });

    const { data } = await axiosPost(`leads/editLead?user_id=${userId}`, leadData);

    dispatch({ type: UPDATE_LEAD_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: UPDATE_LEAD_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
    return error.response.data;
  }
}

export const getActivittyLogsAction = (userId, leadId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ACTIVITY_LOGS_REQUEST });

    const { data } = await axiosGet(`leads/getActivityLogs?user_id=${userId}&lead_id=${leadId}`);
    dispatch({ type: GET_ACTIVITY_LOGS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_ACTIVITY_LOGS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const sendLeadToOperationsAction = (userId, leadId) => async (dispatch) => {
  try {
    dispatch({ type: SEND_LEAD_TO_OPERATIONS_REQUEST });

    const { data } = await axiosGet(`leads/sendLeadToOp?user_id=${userId}&lead_id=${leadId}`);
    dispatch({ type: SEND_LEAD_TO_OPERATIONS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: SEND_LEAD_TO_OPERATIONS_FAIL,
      payload: error.response && error.response.data ? error.response.data : error.message,
    });
  }
}

export const postCsvFileAction = (userId, formData) => async (dispatch) => {
  try {
    dispatch({ type: POST_CSV_FILE_REQUEST });

    // const formData = new FormData();
    // formData.append('file', file);

    const { data } = await axiosPost(`leads/uploadLeads?user_id=${userId}`, formData);
    dispatch({ type: POST_CSV_FILE_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: POST_CSV_FILE_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

export const uploadFileAction = (userId, formData) => async (dispatch) => {
  try {

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    dispatch({ type: POST_FILE_REQUEST });

    const { data } = await axiosPost(`leads/upload_multiple_lead_files?user_id=${userId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    console.log("File data --- " , data)
    dispatch({ type: POST_FILE_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: POST_FILE_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

export const getAllFilesAction = (userId, leadId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_FILES_REQUEST });

    const { data } = await axiosGet(`leads/getLeadFiles?user_id=${userId}&lead_id=${leadId}`);
    dispatch({ type: GET_ALL_FILES_SUCCESS, payload: data?.files });
    return data;
  } catch (error) {
    dispatch({
      type: GET_ALL_FILES_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}

export const resetAllFilesAction = () => async (dispatch) => {
  dispatch({ type: RESET_ALL_FILES });
}

// delete lead
export const getDeleteLeadAction = (userId, leadId) => async (dispatch) => {
  try {
    dispatch({ type: GET_DELETE_LEADS_REQUEST });
    const { data } = await axiosGet(`leads/deleteLead?user_id=${userId}&lead_id=${leadId}`);
    dispatch({ type: GET_DELETE_LEADS_SUCCESS, payload: data?.data });
    return data;
  } catch (error) {
    dispatch({
      type: GET_DELETE_LEADS_FAIL,
      payload: error?.response?.data ? error.response.data : error.message,
    });
  }
}