// Dashboard.js
import React, { useState, useEffect } from "react";
import * as yup from "yup"
import { Controller, useForm } from 'react-hook-form'
import { Button, Col, Input, Row, Select, Spin } from 'antd'
import { yupResolver } from "@hookform/resolvers/yup"
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import { toast } from 'react-toastify'
import { Table } from "lucide-react";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { LabeledDropdown, LabeledInput } from "../../../common/components/LeadComponent/CommonTextField";
import { getApprovedSubmissionsBoardDealAction, getSubmissionsBoardDealAction } from "../../../redux/actions/submissionBoardAction";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import { userIdAtom } from "../../../atom/authuserData/userdata";
import { getApprovedDealsByLenderAction } from "../../../redux/actions/lenderAction";
import { requestCommisionAction } from "../../../redux/actions/commisionAction";
import BackButton from "../SubmissionBoard/backButton";
import moment from "moment";
import axios from "axios";
import APIUrl from "../../../atom/APIUrl";

//scehma
const schema = yup
  .object({
    companyName: yup
      .string()
      .max(100)
      .required("Select company name"),
    date: yup
      .string()
      .required("Date is required"), // Adjust validation if needed
    firstName: yup
      .string()
      .max(100)
      .required("Enter first name"),
    lastName: yup
      .string()
      .max(100)
      .required("Enter last name"),
    phoneNumber: yup
      .string() // Assuming you want exactly 10 digits
      .required("Mobile number is required"),
    email: yup
      .string()
      .email("Invalid Email")
      .required("Email is required"),
    leadTag: yup
      .string()
      .max(100)
      .required("Lead Tag is required"),
    fundingAmount: yup
      .string()
      .required("Funded amount is required"),
    lender: yup
      .string()
      .max(100)
      .required("Enter Lender"),
    agent: yup
      .string()
      .required("Enter agent"),
  })
  .required();

const defaultValues = {
  companyName: "",
  date: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  leadTag: "",
  fundingAmount: "0",
  lender: "",
  agent: "",
}

const formatAmount = (amount) => {
  return new Intl.NumberFormat('en-US', 
      { style: 'currency', currency: 'USD', 
          minimumFractionDigits: 0, 
          maximumFractionDigits: 2
       }).format(amount)
};

const CommisionPortalForm = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const [companiesOption, setCompaniesOption] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [lenderOption, setLenderOption] = useState([]);
  const navigate = useNavigate();
  const { userId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [dealId,setDealId] = useState("")
  const [lenderId,setLenderId] = useState("")
  const [additionalLenders, setAdditionalLenders] = useState([]);
  const [additionalAgent, setAdditionalagent] = useState([]);
  const [dropwdownLenderLoading, setDropwdownLenderLoading] = useState(true);

  const { loading: submissionBordDealLoading, submissionsDeals } = useSelector(state => state.getApprovedSubmissionsBoardDeal);
  const { loading: dealLenderLoading, dealsLender } = useSelector(state => state.getDealsByLender);
  const { loading: requestCommisionLoading, } = useSelector(state => state.postRequestCommision);


  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  
  
  const companyNameWatcher = watch("companyName");;

  
  useEffect(() => {
    const selectedCompany = submissionsDeals?.find(deal => deal?._id === companyNameWatcher);
    const formattedDate = new Date(selectedCompany?.createdAt).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    if (selectedCompany) {
      const deal_id = selectedCompany._id;
      setDealId(deal_id)
      console.log("Deal::",deal_id )
      setSelectedCompanyName(selectedCompany.company_name);
      setValue("date", formattedDate);
      setValue("firstName", selectedCompany?.firstName);
      setValue("lastName", selectedCompany?.lastName);
      setValue("email", selectedCompany?.email);
      setValue("phoneNumber", selectedCompany?.phone);
      setValue("leadTag", selectedCompany?.tag);
      setValue("agent", selectedCompany?.agent_name);
      dispatch(getApprovedDealsByLenderAction(userId, deal_id));

      console.log("Date selected",formattedDate)
    }
  }, [companyNameWatcher, submissionsDeals, setValue, dispatch, userId]);
  


  useEffect(() => {
    const fetchLenders = async () => {
      try {
        const response = await axios.get(`${APIUrl}/commission/getDropdownLenders`);
        console.log("Drop ---  ",response)
        if (response.data.status === 1) {
          console.log(response)
          setAdditionalLenders(response.data.data); // Set additional lenders
        }
      } catch (error) {
        console.error("Error fetching additional lenders:", error);
      } finally {
        setDropwdownLenderLoading(false); // Stop the loading spinner
      }
    };

    fetchLenders();
    const fetchDropAgent = async () => {
      try {
        const response = await axios.get(`${APIUrl}/commission/getDropdownAgents`);
        console.log("Drop ---  ",response)
        if (response.data.status === 1) {
          console.log(response)
          setAdditionalagent(response.data.data); // Set additional lenders
        }
      } catch (error) {
        console.error("Error fetching additional lenders:", error);
      } finally {
        setDropwdownLenderLoading(false); // Stop the loading spinner
      }
    };

    fetchDropAgent();
    if (userId) {
      dispatch(getApprovedSubmissionsBoardDealAction(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (submissionsDeals && submissionsDeals.length > 0) {
      const companies = submissionsDeals.map(deal => ({
        value: deal._id,
        label: deal.company_name,
      }));
      setCompaniesOption(companies);
    }
  }, [submissionsDeals]);

  useEffect(() => {
    if (dealsLender && dealsLender?.length > 0) {
      const options = dealsLender?.map(lender => ({
        value: lender.lender_name,
        label: lender.lender_name,
        lender_id: lender.lender_id,
      }));
      setLenderOption(options);
    }
  }, [dealsLender]);

useEffect(()=>{
  console.log("LEnder id",lenderOption)
},lenderOption)

  const handleTabClick = (tab) => {
    // console.log("Tab ---------------  " , tab)
    setSelectedTab(tab);
  };

  const onSubmit = async (data) => {
    console.log("My data dealId::",dealId)
    console.log("My data lenderId::",lenderId)
    const formattedData = {
      deal_id: dealId,
      lender_id:lenderId,
      agent_funding_amount: data?.fundingAmount,
      // company_name: selectedCompanyName,
      // date: data?.date,
      // owner_firstName: data?.firstName,
      // owner_lastName: data?.lastName,
      // phone: data?.phoneNumber,
      // email: data?.email,
      // leadTag: data?.leadTag,
      // Lender: data?.lender,
      // Agent: data?.agent
    };
    const result = await dispatch(requestCommisionAction(userId, formattedData));
    if (result?.status === 1) {
      toast.success(result?.message || "Submission successful");
      reset(defaultValues);
      navigate('/commision-portal');
    } else {
      toast.error(result?.message || "Submission failed");
    }
  };

  const mergedLenderOptions = [
    ...lenderOption, // Existing lender options
    ...additionalLenders.map(lender => ({
      value: lender._id,
      label: lender.lenderName,
      lender_id: lender._id,
    })),
  ];

  const mergedAgentOptions = [
   // ...lenderOption, // Existing lender options
    ...additionalAgent.map(lender => ({
      value: lender._id,
      label: lender.agentName,
    })),
  ];

  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-auto flex flex-col ">
        <GoodMorningBar userName="Manish" />
        <div className="flex justify-between w-full mr-9 my-6 ">
      <BackButton/>

        </div>
        <div className="flex-grow  mx-2 mb-5 overflow-y-auto">
          <div className="bg-white rounded-lg py-5 px-7 h-auto pb-24">
            <div className="flex justify-between mb-6">
              <div className="text-xl font-bold text-lender-green-text">
                Commision Form
              </div>
            </div>
            <form className="flex flex-col justify-start" onSubmit={handleSubmit(onSubmit)}>
              <Row gutter={16}>
                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-companyName">
                    Company Name
                  </label>
                  <Controller
                    name="companyName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        size='large'
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="label"
                        notFoundContent={submissionBordDealLoading ? <Spin size="small" /> : null}
                        status={errors.companyName ? "error" : undefined}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={companiesOption}
                        onChange={e => field.onChange(e)}
                      />
                    )}
                  />
                  {errors.companyName ? (
                    <small className="text-red-500 capitalize">{errors.companyName.message}</small>
                  ) : null}
                </Col>

                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-date">
                    Date
                  </label>
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-date"
                        type="string"
                        placeholder="Select date"
                        {...field}
                        status={errors.date ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.date && (
                    <small className="text-red-500 capitalize">{errors.date.message}</small>
                  )}
                </Col>

                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-firstName">
                    First Name
                  </label>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-firstName"
                        placeholder="Enter First Name"
                        {...field}
                        status={errors.firstName ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.firstName && (
                    <small className="text-red-500 capitalize">{errors.firstName.message}</small>
                  )}
                </Col>

                <Col span={12}>
                  <label className="mb-1 font-medium" htmlFor="input-lastName">
                    Last Name
                  </label>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-lastName"
                        placeholder="Enter Last Name"
                        {...field}
                        status={errors.lastName ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.lastName && (
                    <small className="text-red-500 capitalize">{errors.lastName.message}</small>
                  )}
                </Col>

                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-phoneNumber">
                    Phone Number
                  </label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-phoneNumber"
                        placeholder="9000000000"
                        {...field}
                        status={errors.phoneNumber ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.phoneNumber && (
                    <small className="text-red-500 capitalize">{errors.phoneNumber.message}</small>
                  )}
                </Col>

                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-email">
                    Email
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-email"
                        placeholder="example@gmail.com"
                        {...field}
                        status={errors.email ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.email && (
                    <small className="text-red-500 capitalize">{errors.email.message}</small>
                  )}
                </Col>

                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-leadTag">
                    Lead Tag
                  </label>
                  <Controller
                    name="leadTag"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-leadTag"
                        placeholder="Enter Lead Tag"
                        {...field}
                        status={errors.leadTag ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.leadTag && (
                    <small className="text-red-500 capitalize">{errors.leadTag.message}</small>
                  )}
                </Col>

                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-fundingAmount">
                    Funding Amount
                  </label>
                  <Controller
                    name="fundingAmount"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-fundingAmount"
                        placeholder="Enter amount"
                        value={field.value ? formatAmount(field.value) : ''} // Format value for display only
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/\D/g, ''); // Only allow digits
                    field.onChange(rawValue); // Update field with raw value
                  }}
                        status={errors.fundingAmount ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.fundingAmount && (
                    <small className="text-red-500 capitalize">{errors.fundingAmount.message}</small>
                  )}
                </Col>

                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-lender">
                    Lender
                  </label>
                  <Controller
                    name="lender"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
            size="large"
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Search to Select"
            optionFilterProp="label"
            notFoundContent={dealLenderLoading ? <Spin size="small" /> : null}
            status={errors.lender ? "error" : undefined}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={mergedLenderOptions}
            onChange={(value, option) => {
              field.onChange(value);
              setLenderId(option.lender_id); 
            }}
          />
                    )}
                  />
                  {errors.lender ? (
                    <small className="text-red-500 capitalize">{errors.lender.message}</small>
                  ) : null}
                </Col>
                <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-lender">
                    Agent
                  </label>
                  <Controller
                    name="lender"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
            size="large"
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Search to Select"
            optionFilterProp="label"
            notFoundContent={dealLenderLoading ? <Spin size="small" /> : null}
            status={errors.lender ? "error" : undefined}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={mergedAgentOptions}
            onChange={(value, option) => {
              field.onChange(value);
              setLenderId(option.lender_id); 
            }}
          />
                    )}
                  />
                  {errors.lender ? (
                    <small className="text-red-500 capitalize">{errors.lender.message}</small>
                  ) : null}
                </Col>

              </Row>
              <Col span={12} className="mb-3">
                  <label className="mb-1 font-medium" htmlFor="input-email">
                    Commision %
                  </label>
                  <Controller
                    name="comm"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        size="large"
                        id="input-email"
                        placeholder="Enter commision percentage"
                        {...field}
                        // status={errors.email ? "error" : undefined}
                      />
                    )}
                  />
                  {errors.email && (
                    <small className="text-red-500 capitalize">{errors.email.message}</small>
                  )}
                </Col>
              <div className='relative bg-white'>
                <Button
                  htmlType="submit"
                  loading={requestCommisionLoading}
                  className='absolute top-10 right-8 w-40 h-9 px-14 py-1 rounded-lg text-white bg-custom-green font-semibold cursor-pointer'
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CommisionPortalForm;
