// Dashboard.js
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import '../../../common/components/Calender.css';
import DashboardTab from "../../../common/components/DashboardTab/DashboardTabs";
import GoodMorningBar from "../../../common/components/GoodmorningBar";
import ReusableTable from "../../../common/tables/Table";
import { FiEye } from "react-icons/fi";
import { getAllLeadsAction, getDeleteLeadAction } from '../../../redux/actions/leadsActions';
import { useRecoilState } from 'recoil';
import { userIdAtom } from '../../../atom/authuserData/userdata';
import { Spin } from 'antd';
import searchIcon from '../../../data/Group 536.png';
import { RiDeleteBinLine } from "react-icons/ri";
import moment from 'moment';
import DeleteConfirmationModal from "../../../common/components/LeadComponent/DeleteConfirmationModal";

const LeadTable = () => {
  const [selectedTab, setSelectedTab] = useState('Information');
  const { userId } = useSelector((state) => state.auth);
  const [leadsTableData, setLeadsTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { role } = useSelector((state) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false); 
  const [leadId, setLeadId] = useState(null); 

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading: allLeadsLoading, allLeads } = useSelector(state => state.getAllLeads);
  const { loading: deleteLeadsLoading } = useSelector(state => state.getDeleteLeads);

  useEffect(() => {
    dispatch(getAllLeadsAction(userId));
    console.log("leads table data-----------", allLeads);
  }, [dispatch, userId]);

  

  useEffect(() => {
    let filteredData = []
    if (searchText !== '') {
      filteredData = allLeads?.filter((lead) => {
        console.log("Lead data --- " , lead)
        return (
          lead?.company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
          lead?.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
          lead?.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
          lead?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
          lead?.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
          lead?.tag?.toLowerCase().includes(searchText.toLowerCase()) || 
          lead?.lead_source?.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    } else {
      filteredData = allLeads
    }

    let leadsData = []
    if (filteredData?.length > 0) {
      filteredData?.forEach((lead, index) => {
        leadsData.push({
          Key: index,
          id: lead?._id,
          cname: lead?.company_name,
          fname: lead?.firstName,
          lname: lead?.lastName,
          email: lead?.email,
          phone: lead?.phone,
          city: lead?.city,
          street: lead?.street,
          tag: lead?.tag,
          leadSource: lead?.lead_source,
          openLead: lead,
          openDeal: 'Eye Icon',
          createdAt: lead?.createdAt,
        })
      })
    }
    setLeadsTableData(leadsData?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt)))
  }, [allLeads, searchText]);

  const showDeleteModal = (leadId) => {
    setLeadId(leadId);
    setIsModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    if (leadId) {
      dispatch(getDeleteLeadAction(userId, leadId));
      setLeadId(null);
      setIsModalVisible(false);
      dispatch(getAllLeadsAction(userId));
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  
    return phoneNumber; // Return the original if it doesn't match the expected format
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Company name',
        accessor: 'cname',
      },
      {
        Header: 'First name',
        accessor: 'fname',
      },
      {
        Header: 'Last name',
        accessor: 'lname',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone number',
        accessor: 'phone',
        Cell: ({value}) =>
          <span>{formatPhoneNumber(value)}</span>
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'Tag',
        accessor: 'tag',
      },
      {
        Header: 'Lead Source Number',
        accessor: 'leadSource',
      },
      {
        Header: 'Open Lead',
        accessor: 'openLead',
        Cell: ({ value }) => 
        <div className="flex gap-4">
          <span>
            <FiEye onClick={(() => navigate('/leadstabs', { state: { lead: value } }))} alt="Open Deal" style={{ cursor: 'pointer', width: '20px', height: '20px' }} />
          </span>
          <span>
            {
              role === 'admin' ? <RiDeleteBinLine alt="Delete Deal" style={{ cursor: 'pointer', width: '20px', height: '20px' }} onClick={() => showDeleteModal(value?._id)} /> : ""
            }
          </span>
        </div>
      },
    ],
    []
  );

  const handleTabClick = (tab) => {
    console.log("Tab ---------------  ", tab)
    setSelectedTab(tab);
  };

  const LabeledTextarea = ({ label, placeholder, className }) => (
    <div className="flex flex-col w-full">
      <label className=" font-medium text-good-morning-text bg-white">{label}</label>
      <textarea
        placeholder={placeholder}
        className={`border border-gray-300 bg-white w-full  mt-3 h-56 resize-none rounded-md ${className}`}
      />
    </div>
  );

  // const users = [
  //   {
  //     'name': 'Olivia Rhye',
  //     'img': 'https://cdn.theorg.com/5f6cf081-3715-4c11-863c-036782203c9b_thumb.jpg'
  //   },
  //   {
  //     'name': 'Phoenix Baker',
  //     'img': 'https://img.freepik.com/free-photo/close-up-photo-amicable-man-black-shirt-posing-his-passport-picture_132075-8192.jpg'
  //   },
  //   {
  //     'name': 'Lana Steiner',
  //     'img': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTg7k5TDqDaaOl1gvZJLojqjPyVLdevAICDg&s'
  //   }];

  return (
    <div className="flex h-screen bg-black">
      <DashboardTab />
      <main className="flex-1 bg-background-color p-6 h-full flex flex-col">
        <GoodMorningBar userName="Manish" />
        <div className="flex-grow overflow-y-auto  mb-5 ">
          {allLeadsLoading || deleteLeadsLoading ?
            <div className='w-full pt-16 flex justify-center items-center' >
              <Spin />
            </div>
            :
            <div className='mt-4'>
              <div className='bg-white rounded-tl-xl rounded-tr-xl p-3'>
                <div className="flex items-center bg-background-color rounded-3xl p-2 h-10 w-full mr-3">
                  <img
                    src={searchIcon}
                    alt="Search Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Search information"
                    className="bg-background-color focus:outline-none pl-3 w-full"
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              <ReusableTable columns={columns} data={leadsTableData} />
            </div>
          }
        </div>
      </main>
      <DeleteConfirmationModal 
        visible={isModalVisible} 
        onConfirm={handleDeleteConfirm} 
        onCancel={() => setIsModalVisible(false)} 
      />
    </div>
  );
};

export default LeadTable;